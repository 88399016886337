import React from "react";

const Lexend = () => {
  return (
    <style jsx global>{`
      @font-face {
        font-family: "Lexend";
        font-weight: 100;
        src: url("/fonts/Lexend/Lexend-Thin.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 200;
        src: url("/fonts/Lexend/Lexend-ExtraLight.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 300;
        src: url("/fonts/Lexend/Lexend-Light.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 400;
        src: url("/fonts/Lexend/Lexend-Regular.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 500;
        src: url("/fonts/Lexend/Lexend-Medium.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 600;
        src: url("/fonts/Lexend/Lexend-SemiBold.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 700;
        src: url("/fonts/Lexend/Lexend-Bold.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 800;
        src: url("/fonts/Lexend/Lexend-ExtraBold.ttf");
      }
      @font-face {
        font-family: "Lexend";
        font-weight: 900;
        src: url("/fonts/Lexend/Lexend-Black.ttf");
      }
    `}</style>
  );
};

const Roboto = () => {
  return (
    <style jsx global>{`
      @font-face {
        font-family: "Roboto";
        font-weight: 100;
        src: url("/fonts/Roboto/Roboto-Thin.ttf");
      }
      @font-face {
        font-family: "Roboto";
        font-weight: 300;
        src: url("/fonts/Roboto/Roboto-Light.ttf");
      }
      @font-face {
        font-family: "Roboto";
        font-weight: 400;
        src: url("/fonts/Roboto/Roboto-Regular.ttf");
      }
      @font-face {
        font-family: "Roboto";
        font-weight: 500;
        src: url("/fonts/Roboto/Roboto-Medium.ttf");
      }
      @font-face {
        font-family: "Roboto";
        font-weight: 700;
        src: url("/fonts/Roboto/Roboto-Bold.ttf");
      }
      @font-face {
        font-family: "Roboto";
        font-weight: 900;
        src: url("/fonts/Roboto/Roboto-Black.ttf");
      }
    `}</style>
  );
};

const Font = () => {
  return (
    <React.Fragment>
      <style jsx global>{`
        @font-face {
          font-family: "Roboto";
          src: url("/fonts/Roboto/roboto-regular.ttf");
        }
        @font-face {
          font-family: "Roboto-Bold";
          src: url("/fonts/Roboto/Roboto-Bold.ttf");
        }
        @font-face {
          font-family: "Campton-bold";
          src: url("/fonts/Campton/Campton-Bold.ttf");
        }
        @font-face {
          font-family: "Campton-light";
          src: url("/fonts/Campton/Campton-Light.otf");
        }
      `}</style>
      <Lexend />
      <Roboto />
    </React.Fragment>
  );
};

export default Font;
