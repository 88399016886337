import React, { ReactNode, useState } from "react";

const SaHiringContext = React.createContext({});

interface SaContextProps {
    children: ReactNode;
}

const SaHiringProvider: React.FC<SaContextProps> = props => {
    const [EduDocumentData, set_EduDocumentData] = useState({});
    const [MaritalDocumentData, set_MaritalDocumentData] = useState({});

    return (
        <SaHiringContext.Provider
            value={{
                eduDocumentData: EduDocumentData,
                setEduDocumentData: set_EduDocumentData,
                maritalDocumentData: MaritalDocumentData,
                setMaritalDocumentData: set_MaritalDocumentData
            }} >
            {props.children}
        </SaHiringContext.Provider>
    );
};

export { SaHiringProvider, SaHiringContext };