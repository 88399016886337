import React, { ReactNode, useState } from "react";

const MitraContext = React.createContext({});

interface MitraContextProps {
    children: ReactNode;
}

const MitraProvider: React.FC<MitraContextProps> = props => {
    const [businessDocumentData, set_businessDocumentData] = useState([]);
    const [storeProductData, set_storeProductData] = useState([]);

    return (
        <MitraContext.Provider
            value={{
                businessDocumentData: businessDocumentData,
                setBusinessDocumentData: set_businessDocumentData,
                storeProductData: storeProductData,
                setStoreProductData: set_storeProductData
            }} >
            {props.children}
        </MitraContext.Provider>
    );
};

export { MitraProvider, MitraContext };