const variables = () => {
  return (
    <style jsx global="true">{`
      * {
        --color-red: #e11931;
        --color-white: #ffffff;
        --color-white-softer: #eeeeee;
        --color-white-soft: #f4f4f4;
        --color-white-dark: #d4d4d4;
        --color-green: #3eac58;
        --color-gray: #404040;
        --color-gray-lighter: #a9a9a9;
        --color-gray-medium: #979797;
        --color-gray-lightest: #efefef;
        --color-black: #010101;
        --container-desktop-size: 960px;
        --bg-color-img: #eee;
        --color-hr-line: #d8d8d8;
      }
    `}</style>
  );
};
export default variables;
